import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;
import Lenis from '@studio-freight/lenis';
import * as _ from 'underscore';
import barba from '@barba/core';
import imagesLoaded from 'imagesloaded';

import { UserAgent } from './lib/UserAgent.js';
import { MatchMediaManager } from './lib/MatchMediaManager.js';
import { Ease } from './lib/Ease.js';
import { Deferred } from './lib/Deferred.js';
import { ResizeManager } from './lib/ResizeManager.js';
import { ScrollManager } from './lib/ScrollManager.js';
import { NoScroll } from './lib/NoScroll.js';
// import { Preload } from './lib/Preload.js';
// import { Parallax } from './lib/Parallax.js';

window._ua = new UserAgent;
window.matchMediaManager = new MatchMediaManager;
window._ease = new Ease;
window.deferred = new Deferred;
window.resizeManager = new ResizeManager;
window.scrollManager = new ScrollManager;
window.noScroll = new NoScroll;

class Index {
  constructor() {
  }

  on() {
    this.$lIndex = $('.l-index');
    this.$lWorks = $('.l-works');
    this.$works = $('.works', this.$lWorks);

    this.circleSize
    this.worksWidth
    this.worksHeight
    this.centerX
    this.centerY
    this.isScroll = false
    this.deg = 360.0 / this.$works.length
    this.red = this.deg * Math.PI / 180.0;
    this.speed = matchMediaManager.device == 'pc' ? 0.00075 : 0.001; // スクロールのスピード
    this.initPositionAjust = matchMediaManager.device == 'pc' ? 0.231 : 0.38;
    this.initPosition = this.red * (this.$works.length / 2 + 1) + this.initPositionAjust; // 1を位置を調整
    this.pageHeight = Math.floor(Math.PI * 2 / this.speed); // スクロールがループする高さ

    this.$lIndex.css({
      height:`${this.pageHeight + resizeManager.height}px`
    });

    this.onScroll();
    this.onResize();
    $(window).resize();

    //動画の再生
    var vids = document.querySelectorAll("video"); vids.forEach(vid => { var playPromise = vid.play(); if (playPromise !== undefined) { playPromise.then(_ => {}).catch(error => {}); }; });          

    this.anim();
  }

  off() {
    this.isScroll = false;
    this.lenis.destroy();
    cancelAnimationFrame(this.animationId);
    resizeManager.off();
  }

  setPosition(s) {
    this.$works.each((i,el) => {
      const x = Math.cos(this.red * i + s * this.speed + this.initPosition) * this.circleSize + this.centerX;
      const y = Math.sin(this.red * i + s * this.speed + this.initPosition) * this.circleSize + this.centerY;
      $(el).css({
        transform: `translate(${x}px, ${y}px)`
      });
    });
  }

  onScroll() {
    if(!this.isScroll){
      this.isScroll = true;

      const _this = this;

      this.lenis = new Lenis({
        infinite: true,
        //smoothWheel: true,
        smoothTouch: true,
      });

      this.lenis.on('scroll', (e) => {
        const s = e.scroll;
        this.setPosition(s);
      });

      const raf = (time) => {
        this.lenis.raf(time);
        this.animationId = requestAnimationFrame(raf);
      }
      raf();
    }
  }

  onResize() {
    resizeManager.on(() => {
      this.circleSize = this.$lWorks.width() / 2;
      this.worksWidth = this.$works.width() / 2;
      this.worksHeight = this.$works.height() / 2;
      this.centerX = this.circleSize - this.worksWidth;
      this.centerY = this.circleSize - this.worksHeight;
      
      this.setPosition($(window).scrollTop());
    });
  }

  anim() {
    $(window).scrollTop(this.pageHeight / 2);
    
    $.timeout(1)
      .done(() => {
        this.$lWorks.addClass('is-loaded');
        this.lenis.scrollTo(this.pageHeight, {duration: 2});
    });    
  }
}

class ArchiveWorks {
  constructor() {
  }

  on() {
    this.$body = $('body');
    this.$lWorks = $('.l-works');
    this.$worksLi = $('.works li', this.$lWorks);
    this.$worksLi1 = this.$worksLi.filter(':nth-child(3n-2)');
    this.$worksLi2 = this.$worksLi.filter(':nth-child(3n-1)');
    this.$worksLi3 = this.$worksLi.filter(':nth-child(3n)');
    this.pageHeight = this.$body.height();
    this.padding = parseInt(this.$worksLi1.css('top'));

    this.onScroll();

    this.$worksLi.hover(
      () => {
        this.$lWorks.addClass('is-hover');
      },
      () => {
        this.$lWorks.removeClass('is-hover');
      })
  }

  off() {
    scrollManager.off();
  }

  onScroll() {
    if(matchMediaManager.device == 'pc'){
      scrollManager.on(() => {
        const s = scrollManager.top;
        const prog = s / (this.pageHeight - resizeManager.height);

        this.$worksLi1.css({
          transform: `translateY(${-this.padding * prog}px)`
        })

        this.$worksLi3.css({
          transform: `translateY(${this.padding * prog}px)`
        })
      });
    }
  }
}

class SingleWorks {
  constructor() {
  }

  on() {
    this.$lHeader = $('.l-header');
    this.$logo = $('.logo', this.$lHeader);
    this.$lVisual = $('.l-visual');
    this.$visual = $('.visual', this.$lVisual);
    this.visualTop
    this.visualHeight
    this.logoTop = parseInt(this.$logo.css('top'));
    this.logoHeight = this.$logo.height();    

    this.onYoutube();
    this.onScroll();
    this.onResize();
  
    $.timeout(500).done(() => {
      $(window).resize();
    });
  }

  off() {
    scrollManager.off();

    if(this.$logo.hasClass('is-disable')){
      this.$logo.removeClass('is-disable');
    }
  }

  onYoutube() {
    if($('#youtube').length !== 0){
      const targetWindow = $('#youtube')[0].contentWindow;

      const ag2ytControl = function(action,arg=null){
        targetWindow.postMessage('{"event":"command", "func":"'+action+'", "args":'+arg+'}', '*');
      };

      this.$lVisual.on('click', () => {
        this.$lVisual.addClass('is-play');
        ag2ytControl('playVideo');
      });
    }
  }

  onScroll() {
    scrollManager.on(() => {
      const s = scrollManager.top;

      if(s > this.visualTop - this.logoTop - this.logoHeight && s < this.visualTop + this.visualHeight - this.logoTop){
        this.$logo.addClass('is-disable');
      }else if(this.$logo.hasClass('is-disable')){
        this.$logo.removeClass('is-disable');
      }
    });
  }

  onResize() {
    resizeManager.on(() => {
      this.visualTop = this.$lVisual.offset().top;
      this.visualHeight = this.$lVisual.height();
    })
  }
}

class Nav {
  constructor() {
    this.$body = $('body');
    this.$lHeader = $('.l-header');
    this.$lNav = $('.l-nav', this.$lHeader);
    this.$navBtn = $('.navBtn', this.$lHeader);
    this.$navInner = $('.inner', this.$lNav)
    this.scrollBarWidth = resizeManager.innerWidth - resizeManager.width;

    this.onClick();
  }

  open() {
    if(matchMediaManager.device == 'pc'){noScroll.on();}

    this.$lNav.addClass('is-open');
    this.$navBtn.addClass('is-open');
  }

  close() {
    if(matchMediaManager.device == 'pc'){noScroll.off();}

    this.$lNav.removeClass('is-open');
    this.$navBtn.removeClass('is-open');

    $.timeout(500).done(() => {
      this.$navInner.scrollTop(0);
    })
  }

  onClick() {
    this.$navBtn.on('click', () => {
      if(!$('.l-nav').hasClass('is-open')){
        this.open();
      }else{
        this.close();
      }
    });
  }
}

class Init {
  constructor() {
    this.$body = $('body');
    this.$main = $('.l-main');
    this.$logo = $('.l-header .logo')

    this.index = new Index;
    this.archiveWorks = new ArchiveWorks;
    this.singleWorks = new SingleWorks;
    this.nav = new Nav;

    resizeManager.init();
    scrollManager.init();

    this.transition();
  }

  transition() {
    const _this = this; 

    barba.init({
      transitions: [{
        beforeOnce() {
          //console.log('---beforeOnce');

          noScroll.on();
          _this.$logo.addClass('is-loading');
        },
        once() {
          imagesLoaded('.l-main').on('always', (instance) => {
            $.timeout(1500).done(() => {
              //console.log('---once');

              noScroll.off();
              $(window).scrollTop(0);
              _this.on();
              _this.$body.removeClass('barba-once');
              _this.$body.addClass('barba-once-to');

              $.timeout(1000).then(() => {
                _this.$logo.removeClass('is-loading');

                return $.timeout(500);
              }).done(() => {
                _this.$body.removeClass('barba-once-to');
              });
            });
          });
        },
        beforeLeave() {
          _this.$body.removeClass('barba-enter-to');
          _this.$body.addClass('barba-leave');
        },
        async leave() {
          //console.log('---leave');

          _this.nav.close();
          noScroll.on();
          _this.off();
          _this.$logo.addClass('is-loading');

          $.timeout(10).done(() => {
            _this.$body.removeClass('barba-leave');
            _this.$body.addClass('barba-leave-to');  
          });
  
          await new Promise((resolve) => {
            return $.timeout(750).done(resolve);
          });
        },
        beforeEnter() {
        },
        async enter() {
          //console.log('---enter');

          _this.$main = $('main').eq(1);

          _this.$body.removeClass('barba-leave-to');
          _this.$body.addClass('barba-enter');
          
          await new Promise((resolve) => {
            imagesLoaded('.l-main').on('always', (instance) => {
              return $.timeout(50).done(resolve); //loading
            });
          });
        },
        after() {
          //console.log('---after');

          _this.$body.removeClass('barba-enter');
          _this.$body.addClass('barba-enter-to');          

          _this.$logo.removeClass('is-loading');
          noScroll.off();
          $(window).scrollTop(0);
          _this.on();
        }
      }]
    });

    // headの更新
    const replaceHead = function(data){
      const head = document.head;
      const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
      const newPageHead = document.createElement('head');
      newPageHead.innerHTML = newPageRawHead;
   
      const removeHeadTags = [ 
          "meta[name='keywords']"
          ,"meta[name='description']"
          ,"meta[property^='og']"
          ,"meta[name^='twitter']"
          ,"meta[itemprop]"
          ,"link[itemprop]"
          ,"link[rel='prev']"
          ,"link[rel='next']"
          ,"link[rel='canonical']"
      ].join(',');
      
      const headTags = head.querySelectorAll(removeHeadTags)
   
      for(let i = 0; i < headTags.length; i++ ){
          head.removeChild(headTags[i]);
      }
      
      const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
   
      for(let i = 0; i < newHeadTags.length; i++ ){
          head.appendChild(newHeadTags[i]);
      }
    }

    barba.hooks.beforeEnter((data) => {
      replaceHead(data);
    })

    //同じURLの場合は遷移させない
    const eventDelete = e => {
      if (e.currentTarget.href === window.location.href) {
        e.preventDefault()
        e.stopPropagation()
        return
      }
    };
    
    const links = [...document.querySelectorAll('a[href]')]
    links.forEach(link => {
      link.addEventListener('click', e => {
        eventDelete(e)
      }, false)
    });
  }

  on() {
    if(this.$main.hasClass('p-index')){
      this.index.on();
    }

    if(this.$main.hasClass('p-archiveWorks')){
      this.archiveWorks.on();
    }

    if(this.$main.hasClass('p-singleWorks')){
      this.singleWorks.on();
    }

    if(this.$main.hasClass('p-contact')){
      $('.grecaptcha-badge').css({'opacity':'1'});
    }else{
      $('.grecaptcha-badge').css({'opacity':'0'});
    }
  }
  
  off() {
    this.$main = $('main');

    if(this.$main.hasClass('p-index')){
      this.index.off();
    }

    if(this.$main.hasClass('p-archiveWorks')){
      this.archiveWorks.off();
    }

    if(this.$main.hasClass('p-singleWorks')){
      this.singleWorks.off();
    }
  }
}

$(() => new Init);